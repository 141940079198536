import { ApplicationController } from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['triggerBtn', 'form', 'input', 'submitBtn']

  get waitingForShipmentOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2c_waiting_for_shipment");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  get waitingForShipmentIntegrationOrdersSectionController() {
    const ordersSections = Array.from(document.querySelectorAll('[data-controller="dashboard--orders-section"]'))
    if (ordersSections.length === 0) return;

    const ordersSection = ordersSections.find(section => section.id === "b2c_waiting_for_shipment_integration");
    if (!ordersSection) return;

    return ordersSection['dashboard--orders-section']
  }

  toggle() {
    event.preventDefault();
    this.triggerBtnTarget.classList.toggle('hidden');
    this.formTarget.classList.toggle('hidden');
  }

  enableSubmitBtn() {
    this.submitBtnTarget.disabled = (event.currentTarget.value == '');
  }

  submit() {
    event.preventDefault();
    if (this.inputTarget.value == '') return;

    this.submitBtnTarget.remove();
    this.element.insertAdjacentHTML('beforeend', `
      <button class="btn btn-primary btn-sm" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">Loading...</span>
      </button>
    `)
    const formData = new FormData(this.formTarget);
    this.fetchWithToken(this.formTarget.action, {
      method: 'PATCH',
      headers: { "Accept": "application/json" },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.errors) {
        this.formTarget.reset();
        return
      }

      this.orderCurrentStatusControllers.forEach(el => el.refresh(data.current_status_html));
      this.rightSideOverlayController.refreshBody(data.order_show_html);
      if (data.waiting_for_shipment_orders_section_html) this.waitingForShipmentOrdersSectionController.refresh(data.waiting_for_shipment_orders_section_html);
      if (data.waiting_for_shipment_integration_orders_section_html) this.waitingForShipmentIntegrationOrdersSectionController.refresh(data.waiting_for_shipment_integration_orders_section_html);
    })
  }
}
